<template>
  <hs-loading v-if="isLoading" />
  <section v-else class="py-4 px-3 d-flex justify-content-center align-items-center">
    <termModal id="term-modal" :kind="kindTerm" />
    <div class="py-5 px-4 px-sm-5 bg-white rounded-sm custom-card">
      <h4 class="title font-weight-bold text-center">{{ $t('accept_terms.title') }}</h4>
      <h6 class="description font-weight-normal text-center pt-3 pb-5">
        {{ $t('accept_terms.description') }}
      </h6>
      <hs-form id="accept-terms-of-use-consense-form" @submit="confirm">
        <hs-checkbox id="terms-of-use-consense" v-model="checkUseTerms" @blur="$v.checkUseTerms.$touch()">
          <span
            class="terms-of-use-consense font-weight-normal"
            v-html="`${$t(`accept_terms.terms_of_use_consense`)}`"
          ></span>
        </hs-checkbox>
        <div class="check-use-terms-error font-weight-bold" v-if="$v.checkUseTerms.$error">
          {{ $t('accept_terms.form.validation.required.use_terms') }}
        </div>
        <div class="d-block d-sm-flex justify-content-center pt-5">
          <hs-button
            id="confirm-button-mobile"
            class="d-block d-sm-none"
            block
            size="lg"
            variant="primary"
            type="submit"
            >{{ $t('accept_terms.form.buttons.confirm') }}</hs-button
          >
          <hs-button
            id="delete-button-mobile"
            @click="deleteAccount"
            class="mt-3 d-block d-sm-none"
            size="lg"
            block
            variant="outline-secondary"
            type="button"
            >{{ $t('accept_terms.form.buttons.delete') }}</hs-button
          >
          <hs-button
            id="delete-button-desktop"
            @click="deleteAccount"
            class="d-none d-sm-block mr-sm-3"
            size="lg"
            variant="link"
            type="button"
            >{{ $t('accept_terms.form.buttons.delete') }}</hs-button
          >
          <hs-button id="confirm-button-desktop" class="d-none d-sm-block" size="lg" variant="primary" type="submit">{{
            $t('accept_terms.form.buttons.confirm')
          }}</hs-button>
        </div>
      </hs-form>
    </div>
  </section>
</template>

<script>
import { hsForm, hsLoading } from '@/components';
import termModal from '@/components/TermModal';
import { mapActions, mapState } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';
import OkMessage from '@/shared/mixins/OkMessage';

export default {
  components: {
    hsForm,
    hsLoading,
    termModal,
  },
  mixins: [OkMessage],
  computed: {
    ...mapState('auth', {
      loggedUser: state => state.loggedUser,
    }),
  },
  data() {
    return {
      checkUseTerms: false,
      isLoading: false,
      kindTerm: '',
    };
  },
  validations: {
    checkUseTerms: {
      checkIfTrue: value => value === true,
    },
  },
  mounted() {
    const accountTermButton = document.getElementById('account_terms_herospark');
    const privacyPolicyButton = document.getElementById('privacy_policy_herospark');
    if (accountTermButton && privacyPolicyButton) {
      accountTermButton.addEventListener('click', () => this.openUseTerms('account_terms'));
      privacyPolicyButton.addEventListener('click', () => this.openUseTerms('privacy_policy'));
    }
  },
  methods: {
    ...mapActions('auth', ['acceptTerms']),
    confirm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isLoading = true;
      this.acceptTerms({ userId: this.loggedUser.id })
        .then(
          () => this.$router.push({ name: 'HomePage' }),
          () => ToastHelper.dangerMessage(this.$t('accept_terms.error_message'))
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    openUseTerms(kind) {
      this.kindTerm = kind;
      this.$bvModal.show('term-modal');
    },
    deleteAccount() {
      this.showOkMessageBox({
        icon: { name: 'trash-alt', color: 'cherry' },
        hideFooter: true,
        size: 'smd',
        contentTitle: this.$t('accept_terms.delete_modal.ask'),
        contentDescription: this.$t('accept_terms.delete_modal.description'),
      });
    },
  },
};
</script>

<style lang="scss">
.custom-card {
  max-width: 651px;
  .title {
    line-height: 115%;
    color: $grey;
  }
  .description {
    line-height: 150%;
    color: $grey-32;
  }
  .terms-of-use-consense {
    font-size: 14px;
    line-height: 150%;
    color: $grey-32;
    /deep/ a {
      color: $cyan;
    }
  }
  .check-use-terms-error {
    font-size: 62.5%;
    color: $cherry-dark;
  }
}
.custom-email,
.custom-email:hover {
  color: $cyan;
}

.description {
  margin-bottom: 1.5rem !important;
}
</style>
